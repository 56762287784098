import * as React from 'react';
import { memo } from "react";
import { List, getTheme, Link, mergeStyleSets, getFocusStyle } from "@fluentui/react";

const generateStyles = (theme) => {
  const { palette, semanticColors, fonts } = theme;
  return mergeStyleSets({
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        display: 'flex',
        selectors: {
          '&:hover': { background: palette.neutralLight },
        },
      },
    ],
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    itemTitle: [
      fonts.xLarge,
      {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: 10,
        marginTop: 10,
      },
    ],
    description: {
      display: '-webkit-box',
      "-webkit-box-orient": "vertical",
      "-webkit-line-clamp": 3,
      overflow: "hidden",
      "text-overflow": "ellipsis",
      "max-height": "4.5em",
      "line-height": "1.5em",
    }
  });
};

const PlanList = ({ items, setPlanIsLoading }) => {
  const theme = getTheme();
  const classNames = React.useMemo(() => generateStyles(theme), [theme]);
  setPlanIsLoading(false);
  return (
      <List
        items={items}
        onRenderCell={(item, index) => (
          item.children && item.children.length > 0 ? (
          <div>
            <h4 className={classNames.itemTitle}>
              <Link href={item.url}>{item.title}</Link>
            </h4>
            <List 
              items={item.children}
              onRenderCell={(item, index) => (
                <div>
                  <div className={classNames.itemContent}>
                    <h6 className={classNames.itemTitle}>
                      <Link href={item.url}>{item.title}</Link>
                    </h6>
                    <p className={classNames.description}>{item.description}</p>
                  </div>
                </div>
              )}
            />
          </div>
          ): (
            <div>
              <div className={classNames.itemContent}>
                <h6 className={classNames.itemTitle}>
                  <Link href={item.url}>{item.title}</Link>
                </h6>
                <p className={classNames.description}>{item.description}</p>
              </div>
            </div>
          )
        )}
      />
  );
};

export default memo(PlanList);
