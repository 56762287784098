import { useState, memo } from "react";
import {
  PrimaryButton,
  TextField,
  Stack,
  StackItem,
} from "@fluentui/react";
import GraphRagAPI from "../../Common/API/graphRagApi";
import { MessageActions } from "../../Common/Utils/Message";

const GraphRagInput = ({ submitInputFunc, setUserQuery, setGraphIsLoading }) => {
  const [goal, SetGoal] = useState("");
  const [projects, SetProjects] = useState("");

  const RespToGraph = (data) => {
    const nodes = [];
    const edges = [];
    const uniqueEntities = new Set();
    data.entities.forEach((item) => {
      if (!uniqueEntities.has(item.entity)) {
        uniqueEntities.add(item.entity);
        nodes.push({
          id: item.entity,
          label: item.entity,
          type: item.type,
          title: item.content_title,
          description: item.description,
          url: item.url,
          raw: item
        });
      }
    });
    data.relationships.forEach((item) => {
      edges.push(item);
    });
    return {nodes: nodes, relationships: edges};
  }
  const submitQuery = async () => {
    try {
      setGraphIsLoading(true);
      const req = {
        "Objective": {  
            "goal": goal,  
            "skills_objective": [],  
            "appliedskills_objective": [],  
            "certifications_objective": [],  
            "projects": projects,  
            "role_objective": [""]  
        },
        "Background": {
            "roles": [],
            "levels": []
        },
        "Timeline": {
            "timeline_week": 4,
            "timeline_total": 100
        },
        "Learning preferences": {
            "learning_preferences": ""
        }
      }
      const resp = await GraphRagAPI.getGraph(req);
      if(resp.status === 200) {
        submitInputFunc(RespToGraph(resp.data));
      }else{
        throw new Error(resp.data);
      }
    } catch (e) {
      setGraphIsLoading(false);
      MessageActions.errorHandler(e);
    }
  }

  return (
        <Stack>
          <Stack>
            <TextField 
              label="Goal"
              multiline 
              rows={3} 
              onChange={(_, newValue) => {
                SetGoal(newValue)
                setUserQuery(newValue)
              }}/>
          </Stack>
          <Stack>
            <TextField 
              label="Projects"
              multiline 
              rows={3} 
              onChange={(_, newValue) => {
                SetProjects(newValue)
              }}/>
          </Stack>
          <Stack>
            <StackItem>
              <PrimaryButton 
                styles={{ root: { marginTop: 10 } }}
                text="Submit" 
                onClick={submitQuery} 
                allowDisabledFocus 
              />
            </StackItem>
          </Stack>
        </Stack>
  );
};

export default memo(GraphRagInput);
