import APIBase from "./APIBase";
import { configs } from "../Config";
import _ from "lodash";
const baseURL = configs.RagApp.graphRagServiceEndpoint;
class GraphRagAPI {
  static getGraph(data) {
    return APIBase.post(`${baseURL}/api/graphrag`, data);
  }
  static getPlan(data) {
    return APIBase.post(`${baseURL}/api/getplan`, data);
  }
}

export default GraphRagAPI;
