import { useState, memo } from "react";
import {
  PrimaryButton,
  TextField,
  Stack,
  StackItem,
} from "@fluentui/react";
import cloneDeep from "lodash/cloneDeep";
import { GraphPrompt } from "../Prompt/graphPrompt";
import { KnowledgeServicePrompt } from "../Prompt/knowledgeServicePrompt";
import GraphRagAPI from "../../Common/API/graphRagApi";
import { MessageActions } from "../../Common/Utils/Message";

const PromptInput = ({ userQuery, graphItems, setGraphItems, submitPromptFunc, inputType, setPlanIsLoading, setPlanItems }) => {
  const isKnowledgeService = inputType === "KnowledgeService";
  const [ prompt, setPrompt] = useState(isKnowledgeService? KnowledgeServicePrompt: GraphPrompt);
  
  const submitPrompt = async () => {
    try {
      setPlanIsLoading(true);
      setPlanItems([]);
      const promptWithContext = prompt + 
      `
      ---Data tables---
      ${JSON.stringify(graphItems)}
      ` +
      (isKnowledgeService?
      `
        Please return the study plan directly in Json format, only include nodes, key name must be nodes, and the value is an array of nodes.
      `:
      `
        Please return the study plan directly in Json format, only include nodes, key name must be nodes, LEARNINGPATH and MODULE are parent-child relationships. LEARNINGPATH contains a children property, which contains the node of MODULE..
      `);
      const resp = await GraphRagAPI.getPlan({prompt: promptWithContext, query: userQuery});
      if(resp.status === 200) {
        const jsonString = resp.data.plan;
        let cleanedJsonString = jsonString.replace(/```json/g, '');
        cleanedJsonString = cleanedJsonString.replace(/```/g, '');
        let jsonObject;
        try {
          jsonObject = JSON.parse(cleanedJsonString);
        } catch (error) {
          throw new Error("Invalid JSON string" + error);
        }
        const nodes = jsonObject.nodes;
        submitPromptFunc(nodes);

        let selectedNodes = [];
        for (let i = 0; i < nodes.length; i++) {
          selectedNodes.push(nodes[i].id);
          if (nodes[i].children) {
            for (let j = 0; j < nodes[i].children.length; j++) {
              selectedNodes.push(nodes[i].children[j].id);
            }
          }
        }

        const newGraphItems = cloneDeep(graphItems);
        for (let i = 0; i < newGraphItems.nodes.length; i++) {
          if (selectedNodes.includes(newGraphItems.nodes[i].id)) {
            newGraphItems.nodes[i].selected = true;
          }
        }
        setGraphItems(newGraphItems);
      }else{
        throw new Error("Failed to get plan");
      }
    } catch (e) {
      setPlanIsLoading(false);
      MessageActions.errorHandler(e);
    }
    
    
  }
  return (
    <Stack>
      <Stack>
        <TextField 
          label="Prompt"
          required={prompt?.length === 0} 
          multiline 
          styles={{ root: { minWidth: 300 } }}
          rows={25} 
          value = {prompt}
          onChange={(_, newValue) => {
            setPrompt(newValue)
          }}/>
      </Stack>
    <Stack>
      <StackItem>
        <PrimaryButton 
          styles={{ root: { marginTop: 10 } }}
          text="Submit" 
          onClick={submitPrompt} 
          allowDisabledFocus 
        />
      </StackItem>
    </Stack>
  </Stack>
  );
};

export default memo(PromptInput);
