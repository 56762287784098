import { useState, memo } from "react";
import DropdownSearch from "../../Components/DropdownSearch";
import knowledgeServiceAPI from "../../Common/API/knowledgeServiceAPI";
import {
  PrimaryButton,
  TextField,
  Stack,
  StackItem,
  SpinButton,
  Position,
} from "@fluentui/react";
import _ from "lodash";
import { MessageActions } from "../../Common/Utils/Message";

const dropdownStyles = {
  root: { width: '20%' },
};

const MethodHybrid = "Hybrid";
const MethodVector = "Vector";
const SearchScopeDocument = "Document";
const SearchScopeTraining = "Training";
const SearchScopeAdaptiveLearning = "Adaptivelearning";
const AdditionalSourcesKubernetesIO = "Kubernetes.io"

const SearchMethodDropDown = [
  {key: MethodHybrid, text: MethodHybrid},
  {key: MethodVector, text: MethodVector},
]

const SearchScopeDropDown = [
  {key: SearchScopeDocument, text: SearchScopeDocument},
  {key: SearchScopeTraining, text: SearchScopeTraining},
  {key: SearchScopeAdaptiveLearning, text: SearchScopeAdaptiveLearning},
]

const AdditionalSourcesDropDown = [
  {key: "", text: ""},
  {key: AdditionalSourcesKubernetesIO, text: AdditionalSourcesKubernetesIO},
]

const KnowledgeServiceInput = ({ submitInputFunc, setUserQuery, setGraphIsLoading }) => {
  const [input, setInput] = useState("");
  const [searchMethod, setSearchMethod] = useState(MethodHybrid);
  const [searchScope, setSearchScope] = useState(SearchScopeDocument);
  const [additionalSources, setAdditionalSources] = useState("");
  const [threshold, setThreshold] = useState(0);
  const [topCount, setTopCount] = useState(5);

  const RespToGraph = (data) => {
    const nodes = [];
    data.items.forEach((item) => {
      nodes.push({
        id: item.id,
        label: item.id,
        title: item.title,
        description: item.caption,
        url: item.contentUrl,
        raw: item
      });
    });
    return {nodes: nodes, relationships: []};
  }

  const checkQuery = () => {
    if (input.length === 0) {
      throw new Error("Please fill in all required fields");
    }
  }

  const submitQuery = async () => {
    try {
      checkQuery();
      setGraphIsLoading(true);
      const resp = await knowledgeServiceAPI.getRelevantItems(searchScope, threshold, additionalSources, topCount, {
        "queryType": searchMethod,
        "input": input
      });
      if(resp.status === 200) {
        submitInputFunc(RespToGraph(resp.data));
      }else{
        throw new Error(resp.data);
      }
    } catch (e) {
      setGraphIsLoading(false);
      MessageActions.errorHandler(e);
    }
  }

  return (
        <Stack>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DropdownSearch
              placeholder='Select SearchMethod'
              label="SearchMethod"
              styles={dropdownStyles}
              options={SearchMethodDropDown}
              multiSelect={false}
              selectedKey={searchMethod}
              onChange={(_, newValue) => {
                setSearchMethod(newValue.key)
                if (newValue.key === MethodHybrid) {
                  setThreshold(0)
                }
              }}
            />
            <SpinButton
              label="Threshold"
              labelPosition={Position.top}
              defaultValue={threshold}
              disabled={searchMethod !== MethodVector}
              min={0}
              max={1}
              step={0.01}
              incrementButtonAriaLabel="Increase value by 0.01"
              decrementButtonAriaLabel="Decrease value by 0.01"
              styles={dropdownStyles}
              onChange={(event) => {
                const newValue = event.target.value;
                setTopCount(parseFloat(newValue));
              }}
            />
            <DropdownSearch
              placeholder='Select SearchScope'
              label="SearchScope"
              styles={dropdownStyles}
              options={SearchScopeDropDown}
              multiSelect={false}
              selectedKey={searchScope}
              onChange={(_, newValue) => {
                setSearchScope(newValue.key)
                if (newValue.key !== SearchScopeDocument) {
                  setAdditionalSources("")
                }
              }}
            />
            <DropdownSearch
              placeholder='Select AdditionalSources'
              label="AdditionalSources"
              styles={dropdownStyles}
              options={AdditionalSourcesDropDown}
              disabled={searchScope !== SearchScopeDocument}
              multiSelect={false}
              selectedKey={additionalSources}
              onChange={(_, newValue) => {
                setAdditionalSources(newValue.key)
              }}
            />
            <SpinButton
              label="Top"
              labelPosition={Position.top}
              value={topCount}
              min={1}
              max={100}
              step={1}
              incrementButtonAriaLabel="Increase value by 1"
              decrementButtonAriaLabel="Decrease value by 1"
              styles={dropdownStyles}
              onChange={(event) => {
                const newValue = event.target.value;
                setTopCount(parseInt(newValue, 10));
              }}
            />
          </Stack>
          <Stack>
            <TextField 
              label="Input"
              styles={{ root: { marginTop: 10 } }}
              required={input?.length === 0} 
              multiline 
              rows={3} 
              onChange={(_, newValue) => {
                setInput(newValue)
                setUserQuery(newValue)
              }}/>
          </Stack>
          <Stack>
            <StackItem>
              <PrimaryButton 
                styles={{ root: { marginTop: 10 } }}
                text="Submit" 
                onClick={submitQuery} 
                allowDisabledFocus 
              />
            </StackItem>
          </Stack>
        </Stack>
  );
};

export default memo(KnowledgeServiceInput);
